import React from 'react';
import { translationContext } from '../../App';


function ResultsListItem({
  name,
  address,
  address2,
  city,
  state,
  zip,
  phone,
  ada,
  ada_comments,
  kitchen,
  kitchen_comments,
  pet,
  pets_comments,
  images,
  distance,
  openOverlay,
}) {
  const translations = React.useContext(translationContext);
  const dictionary = translations.translations
  const MoreDetails = dictionary.find((translation) => translation.code === 'More-Details').text;
  const Phone = dictionary.find((translation) => translation.code === 'Phone').text;
  const PetsAllowed = dictionary.find((translation) => translation.code === 'Pets-Allowed').text;
  const PetsNotAllowed = dictionary.find((translation) => translation.code === 'Pets-Not-Allowed').text;
  const ADAAccommodations = dictionary.find((translation) => translation.code === 'ADA-Accommodations').text;
  const NoADAAccommodations = dictionary.find((translation) => translation.code === 'No-ADA-Accommodations').text;
  const MealsProvided = dictionary.find((translation) => translation.code === 'Meals-Provided').text;
  const NoMealsProvided = dictionary.find((translation) => translation.code === 'No-Meals-Provided').text;

  const handlePhoneNumberClick = (e) => {
    e.stopPropagation();
  };
  return (
    <div
      className="results-list-item"
      onClick={() => openOverlay()}
      onKeyPress={() => openOverlay()}
      tabIndex="0"
    >
      <span className="results-list-item-left">
        <span className="results-list-item-image">
          <img src={images.ImageURL[0]} alt={name} />
          {/* TODO: Wire up if location has been viewed and called. Only show 1 flag max. */}
          <span className="results-list-item-flag">viewed</span>
          {/* <span className="results-list-item-flag loud">called</span> */}
        </span>
        <span className="results-list-item-info">
          <span className="results-list-item-name">{name}</span>
          <span className="results-list-item-address">
            {address} <br />
            {address2}{address2 !== '' && <br />} 
            {city}, {state} {zip} <br />
            <a
              className="results-list-item-phone"
              href={'tel:+1-' + phone}
              onClick={handlePhoneNumberClick}
            >
              {Phone} {phone}
            </a>
          </span>
        </span>
      </span>
      <span className="results-list-item-right">
        <span className="results-list-item-feature-list">
          <span className="results-list-item-feature">
            <span
              className={
                pet === 'Y'
                  ? 'results-list-item-feature-icon material-symbols-rounded green'
                  : 'results-list-item-feature-icon material-symbols-rounded'
              }
              aria-hidden="true"
            >
              {pet === 'Y' ? 'check_circle' : 'block'}
            </span>
            <span className="results-list-item-feature-name">
              {pet === 'Y' ? PetsAllowed : PetsNotAllowed} {pets_comments !== '' && pets_comments.toLowerCase() !== 'null' && <br />}
              {pets_comments.toLowerCase() !== 'null' && pets_comments}
            </span>
          </span>
          <span className="results-list-item-feature">
            <span
              className={
                ada === 'Y'
                  ? 'results-list-item-feature-icon material-symbols-rounded green'
                  : 'results-list-item-feature-icon material-symbols-rounded'
              }
              aria-hidden="true"
            >
              {ada === 'Y' ? 'check_circle' : 'block'}
            </span>
            {ada === 'Y' && (
              <span className="results-list-item-feature-name">
                {ADAAccommodations} {ada_comments !== '' && ada_comments.toLowerCase() !== 'null' && <br />}
                {ada_comments.toLowerCase() !== 'null' && ada_comments}
              </span>
            )}
            {ada === 'N' && (
              <span className="results-list-item-feature-name">
                {NoADAAccommodations} <br />
              </span>
            )}
          </span>
          <span className="results-list-item-feature">
            <span
              className={
                kitchen === 'Y'
                  ? 'results-list-item-feature-icon material-symbols-rounded green'
                  : 'results-list-item-feature-icon material-symbols-rounded'
              }
              aria-hidden="true"
            >
              {kitchen === 'Y' ? 'check_circle' : 'block'}
            </span>
            <span className="results-list-item-feature-name">
              {kitchen === 'Y' ? MealsProvided : NoMealsProvided} {kitchen_comments !== '' && kitchen_comments.toLowerCase() !== 'null' && <br />}
              {kitchen_comments.toLowerCase() !== 'null' && kitchen_comments}
            </span>
          </span>
        </span>
        <span className="results-list-item-distance">{distance} mi</span>
      </span>
    </div>
  );
}

export default ResultsListItem;
