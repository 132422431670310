import React from 'react';
import './Navigation.css';
import SiteLogo from '../../assets/FEMA-logo-white.png';
import { translationContext } from '../../App';

function Navigation({
  userLanguage,
  setUserLanguage,
  englishLabel,
  spanishLabel,
}) {
  const localTranslationContext = React.useContext(translationContext);
  return (
    <nav className="navigation-wrap">
      <div className="navigation-flex">
        <div className="navigation-flex-left">
          <a
            className="navigation-logo"
            href="/app2023"
            title="Return to Search"
          >
            <img src={SiteLogo} alt="FEMA site logo" />
          </a>
          <p className="navigation-title" data-lang={userLanguage}>
            {
              localTranslationContext.translations.find(
                (translation) => translation.code === 'FemaTSA'
              ).text
            }
          </p>
        </div>
        <div className="navigation-flex-right">
          <div className="navigation-translate">
            <div className="navigation-translate-select-icon material-symbols-rounded">
              translate
            </div>
            <select
              className="navigation-translate-select"
              value={userLanguage}
              onChange={(e) => setUserLanguage(e.target.value)}
              aria-label="Select site language"
            >
              <option value="en">{englishLabel}</option>
              <option value="es">{spanishLabel}</option>
            </select>
            <div className="navigation-translate-select-arrow material-symbols-rounded">
              expand_more
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navigation;
