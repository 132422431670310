import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import './ErrorOverlay.css';
import { translationContext } from '../../App';

function ErrorOverlay() {
  const localTranslationContext = React.useContext(translationContext);
  const dictionary = localTranslationContext.translations;
  const errorOverlayOpen = localTranslationContext.errorOverlayOpen;
  const RegNumberIneligible1 = dictionary.find(
    (translation) => translation.code === 'RegNumber-Ineligible-1'
  ).text;
  const RegNumberIneligible2 = dictionary.find(
    (translation) => translation.code === 'RegNumber-Ineligible-2'
  ).text;
  const Back = dictionary.find((translation) => translation.code === 'Back').text;

  useEffect(() => {
    const errorOverlay = document.querySelector('.error-overlay-wrap');
    const errorOverlayClose = document.querySelector('.error-overlay-close');
    errorOverlayClose.addEventListener('click', () => {
      errorOverlay.style.display = 'none';
    });
  }, []);

  useEffect(() => {
    const errorOverlay = document.querySelector('.error-overlay-wrap');
    if (errorOverlayOpen) errorOverlay.style.display = 'flex';
    else errorOverlay.style.display = 'none';
  }, [errorOverlayOpen]);

  return (
    <div className="error-overlay-wrap">
      <div className="error-overlay">
        <a
          className="error-overlay-close"
          onClick={() => localTranslationContext.setErrorOverlayOpen(false)}
        >
          <span className="material-symbols-rounded">close</span>
        </a>
        <div className="error-overlay-centered-icon material-symbols-rounded">
          warning
        </div>
        <p className="error-overlay-headline">{RegNumberIneligible1}</p>
        <p className="error-overlay-text">{RegNumberIneligible2}</p>
        <div className="error-overlay-button-wrap">
          <a
            className="error-overlay-button"
            onClick={() => localTranslationContext.setErrorOverlayOpen(false)}
          >
            {Back}
          </a>
        </div>
      </div>
    </div>
  );
}
export default ErrorOverlay;
