import React, {useState, useEffect} from 'react';
import Slider from 'react-slick';
import './slick.css';

export default function SimpleSlider({hotelName, images}) {
  var settings = {
    arrows: true,
    dots: true,
    infinite: true,
    speed: 250,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const [imageArray, setImageArray] = useState(images.ImageURL);

  return (
    <Slider {...settings}>
       {imageArray.map((image, index) => {
        return <div className='results-list-item-image' key={index}>
                <img src={image} alt={hotelName + index} />
              </div>
      })}
    </Slider>
  );
}
