import React, {useContext} from 'react';
import './Registration.css';
import Logo from '../../assets/FEMA-logo-blue.png';
import ErrorOverlay from '../../components/ErrorOverlay/ErrorOverlay';
import ForgotOverlay from '../../components/ErrorOverlay/ForgotOverlay';
import { translationContext } from '../../App';
import { urlParamsContext } from '../../App';

function Registration() {
  const localTranslationContext = React.useContext(translationContext);
  const urlParams = React.useContext(urlParamsContext);

  const [forgotOverlayOpen, setForgotOverlayOpen] = React.useState(false);

  let dictionary = localTranslationContext.translations;
  let language = localTranslationContext.language;
  let FEMATSA = dictionary.find((translation) => translation.code === 'FemaTSA').text;
  let EnterRegNumber = dictionary.find((translation) => translation.code === 'Enter-RegNumber').text;
  let Submit = dictionary.find((translation) => translation.code === 'Submit').text;
  let ForgotRegNumber = dictionary.find((translation) => translation.code === 'Forgot-RegNumber').text;
  let Eligible1 = dictionary.find((translation) => translation.code === 'Eligible-1').text;
  let Eligible2 = dictionary.find((translation) => translation.code === 'Eligible-2').text;
  

  document.title = FEMATSA;

  return (
    <div className="registration-page">
      <div className="registration-page-background">&nbsp;</div>
      <div className="registration-top-translate-bar">
        <div className="registration-translate-logo">
          <img src={Logo} alt="FEMA logo" />
        </div>
        <div className="registration-translate-text" data-lang={language}>
          {FEMATSA}
        </div>
        {language === 'en' && (
          <div className="registration-translate-radio-wrap">
            <label className="registration-translate-radio-label">
              <input
                className="registration-translate-radio-input"
                type="radio"
                name="language"
                value="english"
                defaultChecked
                onClick={(e) => localTranslationContext.setUserLanguage('en')}
              />
              <span className="registration-translate-radio-text">English</span>
            </label>
            <label className="registration-translate-radio-label">
              <input
                className="registration-translate-radio-input"
                type="radio"
                name="language"
                value="español"
                onClick={(e) => localTranslationContext.setUserLanguage('es')}
              />
              <span className="registration-translate-radio-text">Español</span>
            </label>
          </div>
        )}
        {language === 'es' && (
          <div className="registration-translate-radio-wrap">
            <label className="registration-translate-radio-label">
              <input
                className="registration-translate-radio-input"
                type="radio"
                name="language"
                value="english"
                onClick={(e) => localTranslationContext.setUserLanguage('en')}
              />
              <span className="registration-translate-radio-text">English</span>
            </label>
            <label className="registration-translate-radio-label">
              <input
                className="registration-translate-radio-input"
                type="radio"
                name="language"
                value="español"
                defaultChecked
                onClick={(e) => localTranslationContext.setUserLanguage('es')}
              />
              <span className="registration-translate-radio-text">Español</span>
            </label>
          </div>
        )}
      </div>
      <form
        className="registration-form"
        onSubmit={(e) => {
          urlParams.validateRegNum(urlParams.regNum);
          e.preventDefault();
          console.log('submitted');
        }}
      >
        <h1 className="registration-h1">{EnterRegNumber}</h1>
        <div className="registration-input-wrap">
          <input
            className="registration-input"
            type="number"
            pattern="\d*"
            min="0"
            max="999999999"
            placeholder="ex: 123456789"
            value={urlParams.regNum}
            onChange={(e) => urlParams.setRegNum(e.target.value)}
            aria-label="Enter Registration Number"
          />
        </div>
        <button className="registration-submit" type="submit">
          {Submit}
        </button>
        <button
          type="button"
          className="registration-forgot"
          onClick={() => setForgotOverlayOpen(true)}
        >
          {ForgotRegNumber}
        </button>
      </form>
      <div className="registration-step-wrap">
        <div className="registration-step">
          <div className="registration-step-number">1</div>
          <div className="registration-step-title">Search for a hotel</div>
          <div className="registration-step-text">
            Use the form to find a hotel that meets your needs. You&apos;ll need
            the registration ID provided you applied for assistance.
          </div>
        </div>
        <div className="registration-step">
          <div className="registration-step-number">2</div>
          <div className="registration-step-title">Call the hotel</div>
          <div className="registration-step-text">
            Tell them you were referred by FEMA. Provide the hotel with X, Y,
            and Z.
          </div>
        </div>
        <div className="registration-step">
          <div className="registration-step-number">3</div>
          <div className="registration-step-title">
            FEMA will cover your costs
          </div>
          <div className="registration-step-text">
            FEMA will cover your hotel costs for as long as you are unable to
            live in your home.
          </div>
        </div>
      </div>
      <div className="registration-legal-wrap">
        <p className="registration-legal-text">{Eligible1}</p>
        <p className="registration-legal-text">{Eligible2}</p>
      </div>
      {localTranslationContext.errorOverlayOpen && <ErrorOverlay />}
      {forgotOverlayOpen && (
        <ForgotOverlay setForgotOverlayOpen={setForgotOverlayOpen} />
      )}
    </div>
  );
}

export default Registration;
