import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { fetchHotelData } from '../services/mockHotelService';

export const HotelContext = createContext();

export const HotelProvider = ({ children }) => {
  const [hotels, setHotels] = useState([]);

  useEffect(() => {
    fetchHotelData().then((data) => {
      setHotels(data.Hotel);
    });
  }, []);

  return (
    <HotelContext.Provider value={{ hotels, setHotels }}>
      {children}
    </HotelContext.Provider>
  );
};

HotelProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
