let address = '';
if (window.location.origin.includes("femaevachotels.com")) {
    address = window.location.origin
} 
else if(NODE_ENVIRONMNET.WEBPACK_SERVE) {
    address = '/api'
} else {
    address = '/app2023/php'
}

export default address;