import React, {useState, useEffect} from 'react';
import './Find.css';
import Navigation from '../../components/Navigation/Navigation';
import BackgroundImage from '../../assets/background.jpg';
import Logo from '../../assets/FEMA-logo-blue.png';
import LocationSearchInput from '../../components/LocationSearchInput/LocationSearchInput';
import { urlParamsContext } from '../../App';
import { translationContext } from '../../App';

document.title = 'Find a Place to Stay | FEMA TSA Lodging Providers';

function Results() {
  const translations = React.useContext(translationContext);
  const dictionary = translations.translations;
const urlParams = React.useContext(urlParamsContext);
  const FindAPlaceToStay = dictionary.find((translation) => translation.code === 'Place-To-Stay').text;
  const FEMATSA = dictionary.find((translation) => translation.code === 'FemaTSA').text;
  const HotelsWithin = dictionary.find((translation) => translation.code === 'Hotels-Within').text;
  const ShowResults = dictionary.find((translation) => translation.code === 'Show-Results').text;
  const FieldNotEmpty = dictionary.find((translation) => translation.code === 'Field-Not-Empty').text;
  const language = translations.language;
  // console.log(translations.language);
  const setLanguage = translations.dictionaryDispatch;
  const setRadiusState = urlParams.setRadius
  const setSearchState = urlParams.setSearch;
  const [englishLabel, setEnglishLabel] = useState(language);
  const [userLanguage, setUserLanguage] = useState('');
  const [spanishLabel, setSpanishLabel] = useState('');
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const [searchRequiredError, setSearchRequiredError] = useState(false);

  document.title = `${FindAPlaceToStay} | ${FEMATSA}`;

  function setLanguageLabels(outerWidth) {
    if (outerWidth < 750) {
      setEnglishLabel('EN');
      setSpanishLabel('ES');
    } else {
      setEnglishLabel('English');
      setSpanishLabel('Español');
    }
  }

  useEffect(() => {
    const regNumCookie = document.cookie.replace(
      /(?:(?:^|.*;\s*)regNum\s*\=\s*([^;]*).*$)|^.*$/,
      '$1'
    );
    //If RegNum is not set, redirect to registration page
    if (
      (regNumCookie === undefined ||
        regNumCookie === '' ||
        regNumCookie === null) &&
      (urlParams.regNum === undefined ||
        urlParams.regNum === '' ||
        urlParams.regNum === null)
    ) {
      window.location.href = '/app2023/';
      return;
    }

    const handleWindowResize = () => {
      setWindowSize([window.outerWidth, window.outerHeight]);
      setLanguageLabels(window.outerWidth);
    };

    window.addEventListener('resize', handleWindowResize);
    setLanguageLabels(window.outerWidth);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

      //Get user language from browser
  useEffect(() => {
    if (userLanguage === '') {
      let userLang = navigator.language || navigator.userLanguage;
      userLang = userLang.substring(0, 2);
      if (userLang !== 'en' && userLang !== 'es') {
        userLang = 'en';
      }
      setUserLanguage(userLang);
      setLanguageLabels(window.outerWidth);
    }
  }, [userLanguage]);

  useEffect(() => {
    setSearchRequiredError(false);
  }, [urlParams.search]);

function sendToResults (){
  let toResults = true
  if (urlParams.search === undefined || urlParams.search === null || urlParams.search === '') {
    setSearchRequiredError(true);
    toResults = false;
  }
  if (urlParams.radius === undefined || urlParams.radius === null || urlParams.radius === '') {
    setRadiusState(25);
  }
  if(toResults)
  {
    window.location.href = `#/results?search=${urlParams.search}&radius=${urlParams.radius}`;
  }
}

  return (
    <div className="find-wrap">
      <div className="find-background">
        <img
          className="find-background-image"
          src={BackgroundImage}
          alt="hotel room key"
        />
      </div>
      <Navigation
        userLanguage={language}
        setUserLanguage={setLanguage}
        englishLabel={englishLabel}
        spanishLabel={spanishLabel}
      />
      <div className="find-panel">
        <div className="find-panel-branding">
          <a className="find-panel-branding-logo" href="/app2023">
            <img
              className="find-panel-branding-image"
              src={Logo}
              alt="FEMA logo"
            />
          </a>
          <p className="find-panel-banding-text" data-lang={language}>{FEMATSA}</p>
        </div>
        <form
          className="find-panel-form"
          onSubmit={(e) => {
            setTimeout(sendToResults, 200);
            e.preventDefault();
          }}
        >
          <h1 className="find-panel-form-title">{FindAPlaceToStay}</h1>
          <div
            className={`find-panel-form-search-wrap ${
              searchRequiredError ? 'error' : ''
            }`}
          >
            <LocationSearchInput
              setLatitude={urlParams.setLatitude}
              setLongitude={urlParams.setLongitude}
            />
            {searchRequiredError && (
              <p className="find-panel-form-search-validation">
                {FieldNotEmpty}
              </p>
            )}
          </div>
          <fieldset className="find-panel-radio-wrap">
            <legend className="find-panel-radio-legend">{HotelsWithin}</legend>
            <div className="find-panel-radio-flex">
              <label className="find-panel-radio" htmlFor="mi25">
                <input
                  className="find-panel-radio-input"
                  type="radio"
                  id="mi25"
                  name="mile-radius"
                  onClick={() => setRadiusState(25)}
                  defaultChecked
                />
                <span className="find-panel-radio-text">25 mi</span>
              </label>
              <label className="find-panel-radio" htmlFor="mi50">
                <input
                  className="find-panel-radio-input"
                  type="radio"
                  id="mi50"
                  name="mile-radius"
                  onClick={() => setRadiusState(50)}
                />
                <span className="find-panel-radio-text">50 mi</span>
              </label>
              <label className="find-panel-radio" htmlFor="mi100">
                <input
                  className="find-panel-radio-input"
                  type="radio"
                  id="mi100"
                  name="mile-radius"
                  onClick={() => setRadiusState(100)}
                />
                <span className="find-panel-radio-text">100 mi</span>
              </label>
            </div>
          </fieldset>
          <button className="find-panel-form-button" type="submit">
            {ShowResults}
          </button>
        </form>
      </div>
    </div>
  );
}

export default Results;
