import React, { useEffect, useState, useRef } from 'react';
import Autocomplete from 'react-google-autocomplete';
import config from '../../config.json';
import { translationContext } from '../../App';
import { urlParamsContext } from '../../App.js';

/**
 * Enable "Enter" key support for the autocomplete
 * @param {HTMLInputElement} input - The input El
 */
function enableEnterKey(input) {
  if (!input) return;

  /* Store original event listener */
  const _addEventListener = input.addEventListener;

  const addEventListenerWrapper = (type, listener) => {
    if (type === 'keydown') {
      /* Store existing listener function */
      const _listener = listener;
      listener = (event) => {
        /* Simulate a 'down arrow' keypress if no address has been selected */
        const suggestionSelected =
          document.getElementsByClassName('pac-item-selected').length;
        if (event.key === 'Enter' && !suggestionSelected) {
          const e = new KeyboardEvent('keydown', {
            key: 'ArrowDown',
            code: 'ArrowDown',
            keyCode: 40,
          });
          _listener.apply(input, [e]);
        }
        _listener.apply(input, [event]);
      };
    }
    _addEventListener.apply(input, [type, listener]);
  };

  input.addEventListener = addEventListenerWrapper;
}

/**
 * Select the first location
 * @param {HTMLInputElement} input - The input El
 */
function selectFirstLocation(input) {
  input.dispatchEvent(
    new KeyboardEvent('keydown', {
      key: 'ArrowDown',
      code: 'ArrowDown',
      keyCode: 40, // down arrow
    })
  );
  input.dispatchEvent(
    new KeyboardEvent('keydown', {
      key: 'ArrowDown',
      code: 'ArrowDown',
      keyCode: 13, // return key
    })
  );
}

export default function LocationSearchInput({ setLatitude, setLongitude }) {
  const dictionary = React.useContext(translationContext);
  const urlParams = React.useContext(urlParamsContext);
  const [searchLabel, setSearchLabel] = useState();
  const inputElement = useRef();

  useEffect(() => {
    // console.log('The Important Dictionary', dictionary)
    const searchText = dictionary?.translations?.find(
      (x) => x.code === 'Search-Results-bar'
    ).text;
    if (searchText !== undefined && searchText !== '' && searchText !== null) {
      setSearchLabel(searchText);
    }
  }, [dictionary?.versionCounter]);

  // Automatically do a search on mount if location is in the URL
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.hash.split('?')[1]);
    const locationName = urlParams.get('search');
    if (locationName) {
      inputElement.current.focus();
      setTimeout(() => {
        selectFirstLocation(inputElement.current);
      }, 1000);
    }
  }, []);

  // Support the Enter key in autocomplete field
  useEffect(() => {
    enableEnterKey(inputElement.current);
  }, []);

  return (
    <div className="results-list-search-options-search-bar">
      <Autocomplete
        ref={inputElement}
        className="results-list-search-input"
        type="search"
        aria-label={searchLabel}
        placeholder={searchLabel}
        apiKey={config.GOOGLE_MAPS_KEY} // Use apiKey or clientKey
        value={urlParams.search || ''}
        onChange={(e) => {
          urlParams.setSearch(e.target.value);
        }}
        //clientKey={config.GOOGLE_MAPS_CLIENT_ID}
        onPlaceSelected={(place, ref) => {
          if (!place) return;
          // console.log(place.geometry.location.toJSON());
          setLatitude(place.geometry.location.toJSON().lat);
          setLongitude(place.geometry.location.toJSON().lng);
          ref.value = ref.value.replace(', USA', '');
          urlParams.setSearch(ref.value);
        }}
        options={{
          types: [
            'street_address',
            'postal_code',
            'locality',
            'administrative_area_level_2',
          ],
          componentRestrictions: { country: ['us', 'pr', 'vi', 'gu', 'mp'] },
          fields: ['address_components', 'geometry'],
        }}
      />
      <button
        className="results-list-search-submit"
        onMouseDown={(e) => e.preventDefault()}
        onClick={() => {
          selectFirstLocation(inputElement.current);
        }}
      >
        <span className="material-symbols-rounded">search</span>
      </button>
    </div>
  );
}
