import React, { useState, useReducer, useEffect } from 'react';
import './App.css';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/Home';
import { HotelProvider } from './contexts/HotelProvider';
import Registration from './pages/Registration/Registration';
import Find from './pages/Find/Find';
import apiAdress from './utils/apiAddress';
import Results from './pages/Results/Results';
import Help from './pages/Help/Help';
import NotFound from './pages/NotFound/NotFound';
import { FetchTranslationsByLanguage } from './services/translation';

export const urlParamsContext = React.createContext();
export const translationContext = React.createContext();

function App() {
  //Get URL Parameters for regNum, radius, and address
  const urlParams = new URLSearchParams(window.location.hash.split('?')[1]);
  const radius = urlParams.get('radius');
  const search = urlParams.get('search');
  const regNumURL = urlParams.get('regNum');
  const regNumCookie = document.cookie.replace(
    /(?:(?:^|.*;\s*)regNum\s*\=\s*([^;]*).*$)|^.*$/,
    '$1'
  );
  const languageCookie = document.cookie.replace(
    /(?:(?:^|.*;\s*)language\s*\=\s*([^;]*).*$)|^.*$/,
    '$1'
  );
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const [radiusState, setRadiusState] = useState();
  const [searchState, setSearchState] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [errorOverlayOpen, setErrorOverlayOpen] = useState(false);
  const [regNum, _setRegNum] = useState();
  const [versionCounter, setVersionCounter] = useState(0);
  const [userLanguage, _setUserLanguage] = useState('init');

  const setRegNum = (regNum) => {
    if (regNum !== null && regNum !== undefined) {
      _setRegNum(regNum);
      document.cookie = 'regNum=' + regNum + '; path=/';
    }
  };

  const setUserLanguage = (lang) => {
    if (lang !== null && lang !== undefined) {
      _setUserLanguage(lang);
      document.cookie = 'language=' + lang + '; path=/';
    }
  };
  

  const [dictionary, setDictionary] = useState(
    FetchTranslationsByLanguage('en')
  );

  useEffect(() => {
    //Set regNum
    const registrationNumber =
      regNumCookie !== 'null' &&
      regNumCookie !== null &&
      regNumCookie !== '' &&
      regNumCookie !== undefined
        ? regNumCookie
        : regNumURL;
    setRegNum(registrationNumber);

    let userLang = navigator.language || navigator.userLanguage
    let startingLanguage =
    languageCookie !== undefined &&
      languageCookie !== null &&
      languageCookie !== '' &&
      languageCookie !== 'null'
        ? languageCookie
        : userLang.substring(0, 2);
    setUserLanguage(startingLanguage);

    //Set radius
    if (radius === null || radius === '' || radius === undefined) {
      setRadiusState(25);
    } else {
      if (radius >= 100) {
        setRadiusState(100);
      } else if (radius >= 50 && radius < 100) {
        setRadiusState(50);
      } else if (radius >= 25 && radius < 50) {
        setRadiusState(25);
      } else {
        setRadiusState(25);
      }
    }

    //Set search
    if (search === null || search === '' || search === undefined) {
      setSearchState('');
    } else {
      setSearchState(search);
    }
  }, []);

  useEffect(() => {
    //Update URL Parameters
    //get current URL Path
    let urlString = '';
    if (
      searchState !== null &&
      searchState !== '' &&
      searchState !== undefined
    ) {
      urlString += '?search=' + searchState;

      if (
        radiusState !== null &&
        radiusState !== '' &&
        radiusState !== undefined
      ) {
        urlString += '&radius=' + radiusState;
      }
    } else {
      //if search was cleared then remove search from url'
      let urlString2 = window.location.href.split('?')[0];
      console.log('urlString: ' + urlString2);
      window.history.pushState(null, null, urlString2);
    }

    if (urlString !== '') {
      urlString = window.location.href.split('?')[0] + urlString;
      window.history.pushState(null, null, urlString);
    }
  }, [radiusState, searchState]);

  const reducer = (state, action) => {
    switch (action) {
      case 'en':
        setUserLanguage('en');
        break;
      case 'es':
        setUserLanguage('es');
        break;
      default:
        return state;
    }
  };

  //Sets the initial language from the browser
  useEffect(() => {
    let returnLang = userLanguage;
    if ((returnLang === undefined || returnLang === '') && languageCookie !== 'null' && languageCookie !== null && languageCookie !== '' && languageCookie !== undefined) {
      returnLang = languageCookie
      setUserLanguage(languageCookie);
      return;
    }

    if ((userLanguage === undefined || userLanguage === '') && languageCookie === 'null') {
      let userLang = navigator.language || navigator.userLanguage;
      userLang = userLang.substring(0, 2);
      if (userLang !== 'en' && userLang !== 'es') {
        userLang = 'en';
      }
      setUserLanguage(userLang);
      returnLang = userLang;
    }
    setDictionary(updateHardCodedText(returnLang));
  }, [userLanguage]);

  //Update the hard coded text with the translated text
  function updateHardCodedText(overRideLanguage) {
    if (
      (userLanguage !== undefined && userLanguage !== '') ||
      overRideLanguage !== undefined
    ) {
      const lang =
        overRideLanguage !== undefined ? overRideLanguage : userLanguage;
      return FetchTranslationsByLanguage(lang);
    }
  }

  //dictionary hook
  useEffect(() => {
    // console.log('*****************************          Updating dictionar:         ************************', dictionary)
    setVersionCounter(versionCounter + 1);
  }, [dictionary]);

  const [userDictionary, dispatch] = useReducer(reducer, dictionary);

  //Validate the registration number
  const validateRegNum = (regNum) => {
    fetch(
      apiAdress +
        '/evac_hotel_api.php?validate=FEMAEvacAPI' +
        '&reg_number=' +
        regNum,
      {
        method: 'GET',
        headers: { 'Content-Type': '*' },
      }
    )
      .then((res) => {
        const tempRes = res.json();
        return tempRes;
      })
      .then((data) => {
    if (data.Status.toLowerCase() === 'success') { //|| regNum === '000111' || regNum === '000222'
          //update url to /results
          setRegNum(regNum);
          setRadiusState(25);
          setSearchState('');
          setErrorOverlayOpen(false);

      window.location.href = '/app2023/#/find';
        } else {
          const error = data['Error Message'];
          console.log('Error: ', error);
          setErrorMessage(error);
          setErrorOverlayOpen(true);
        }
      })
      .catch((err) => {
        console.log('Error -', err);
        setErrorOverlayOpen(true);
      });
  };

  return (
    <HotelProvider>
      <translationContext.Provider
        value={{
          translations: dictionary,
          language: userLanguage,
          setUserLanguage: setUserLanguage,
          dictionaryDispatch: dispatch,
          versionCounter: versionCounter,
          errorMessage: errorMessage,
          errorOverlayOpen: errorOverlayOpen,
          setErrorOverlayOpen: setErrorOverlayOpen,
        }}
      >
        <urlParamsContext.Provider
          value={{
            regNum: regNum,
            setRegNum: setRegNum,
            radius: radiusState,
            setRadius: setRadiusState,
            search: searchState,
            setSearch: setSearchState,
            validateRegNum: validateRegNum,
            latitude: latitude,
            setLatitude: setLatitude,
            longitude: longitude,
            setLongitude: setLongitude,
          }}
        >
          <Router>
            <Routes>
              <Route path="/" element={<Registration />} />
              <Route path="/find" element={<Find />} />
              <Route path="/results" element={<Results />} />
              <Route path="/help" element={<Help />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Router>
        </urlParamsContext.Provider>
      </translationContext.Provider>
    </HotelProvider>
  );
}

export default App;
