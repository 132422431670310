import React, { useContext, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './ErrorOverlay.css';
import { translationContext } from '../../App';

function ForgotOverlay({setForgotOverlayOpen}) {
  const localTranslationContext = useContext(translationContext);
  const dictionary = localTranslationContext.translations;

  const ForgotRegNumber1 = dictionary.find(
    (translation) => translation.code === 'Forgot-RegNumber-Detail-1'
    ).text;
  const ForgotRegNumber2 = dictionary.find(
    (translation) => translation.code === 'Forgot-RegNumber-Detail-2'
    ).text;
  const Back = dictionary.find(
    (translation) => translation.code === 'Back'
  ).text;

  return (
    <div className="forgot-overlay-wrap">
      <div className="error-overlay">
        <a
          className="error-overlay-close forgot-overlay-close"
          onClick={() => setForgotOverlayOpen(false)}
        >
          <span className="material-symbols-rounded">close</span>
        </a>
        {/* <p className="error-overlay-headline">[Forgot Number Headline]</p> */}
        <p className="error-overlay-text wide" dangerouslySetInnerHTML={{__html:ForgotRegNumber1}}></p>
        <p className="error-overlay-text wide">{ForgotRegNumber2}</p>
        <div className="error-overlay-button-wrap">
          <a
            className="error-overlay-button"
            onClick={() => setForgotOverlayOpen(false)}
          >
            {Back}
          </a>
        </div>
      </div>
    </div>
  );
}
export default ForgotOverlay;
