import React from 'react';
import './Help.css';
import Navigation from '../../components/Navigation/Navigation';

function Help() {
  return (
    <div className="help-page-wrap">
      <Navigation />
      <div className="help-page-center">
        <h1>Need Help?</h1>
      </div>
    </div>
  );
}

export default Help;
