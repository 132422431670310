import React, { useEffect, useState, useContext } from 'react';
import './Results.css';
import Navigation from '../../components/Navigation/Navigation';
import ResultsListItem from '../../components/ResultsListItem/ResultsListItem';
import BackgroundImage from '../../assets/background.jpg';
import Loading from '../../components/Loading/Loading';
import ResultsOverlay from '../../components/ResultsOverlay/ResultsOverlay';
import LocationSearchInput from '../../components/LocationSearchInput/LocationSearchInput';
import apiAdress from '../../utils/apiAddress';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import ResizeObserver from 'resize-observer-polyfill';
import { translationContext } from '../../App';
import { FetchTranslationByCodeAndLanguage } from '../../services/translation';
import { urlParamsContext } from '../../App';
import { fetchMockHotelsJSON } from '../../services/mockHotelService';

window.ResizeObserver = ResizeObserver;
document.title = 'Results | FEMA TSA Lodging Providers';

/*Start of Results Function*/
function Results() {
  const urlParams = React.useContext(urlParamsContext);
  const localTranslationContext = useContext(translationContext);
  const dictionary = localTranslationContext.translations;
  const language = localTranslationContext.language;
  const setLanguage = localTranslationContext.dictionaryDispatch;
  const FEMATSA = dictionary.find((translation) => translation.code === 'FemaTSA').text
  const Results = dictionary.find((translation) => translation.code === 'Results').text
  const [searchLabel, setSearchLabel] = useState();
  const [hotels, setHotels] = useState(null);
  const [userLanguage, setUserLanguage] = useState('');
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const [englishLabel, setEnglishLabel] = useState('');
  const [spanishLabel, setSpanishLabel] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [resultErrorMessage, setResultErrorMessage] = useState();
  const [selectedHotel, setSelectedHotel] = useState(null);

  document.title = `${Results} | ${FEMATSA}`;

  function setLanguageLabels(outerWidth) {
    if (outerWidth < 750) {
      setEnglishLabel('EN');
      setSpanishLabel('ES');
    } else {
      setEnglishLabel('English');
      setSpanishLabel('Español');
    }
  }

  useEffect(() => {
    const regNumCookie = document.cookie.replace(
      /(?:(?:^|.*;\s*)regNum\s*\=\s*([^;]*).*$)|^.*$/,
      '$1'
    );
    //If RegNum is not set, redirect to registration page
    if (
      (regNumCookie === undefined ||
        regNumCookie === '' ||
        regNumCookie === null) &&
      (urlParams.regNum === undefined ||
        urlParams.regNum === '' ||
        urlParams.regNum === null)
    ) {
      window.location.href = '/app2023/';
      return;
    }

    const handleWindowResize = () => {
      setWindowSize([window.outerWidth, window.outerHeight]);
      setLanguageLabels(window.outerWidth);
    };

    window.addEventListener('resize', handleWindowResize);
    setLanguageLabels(window.outerWidth);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  //Get user language from browser
  useEffect(() => {
    if (userLanguage === '') {
      let userLang = navigator.language || navigator.userLanguage;
      userLang = userLang.substring(0, 2);
      if (userLang !== 'en' && userLang !== 'es') {
        userLang = 'en';
      }
      setUserLanguage(userLang);
      setLanguageLabels(window.outerWidth);
      updateHardCodedText(userLang);
    }
  }, [userLanguage]);

  function updateHardCodedText(userLang) {
    setResultErrorMessage(
      FetchTranslationByCodeAndLanguage('No-hotels-found', userLang)
    );
  }

  useEffect(() => {
    if (urlParams.latitude !== 0 && urlParams.latitude !== undefined && urlParams.longitude !== 0 && urlParams.longitude !== undefined && (urlParams.regNum !== undefined || urlParams.regNum !== '' || urlParams.regNum !== null || urlParams.regNum !== 'null')) {
      // console.log('urlParams', urlParams.latitude, urlParams.longitude, urlParams.regNum);
      setIsLoading(true);
      fetch(
        apiAdress +
          '/evac_hotel_api.php?validate=FEMAEvacAPI' +
          '&latitude=' +
          urlParams.latitude +
          '&longitude=' +
          urlParams.longitude +
          '&radius=' +
          urlParams.radius +
          '&reg_number=' +
          urlParams.regNum,
        {
          method: 'GET',
          headers: { 'Content-Type': '*' },
        }
      )
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          if (data.Status.toLowerCase() === 'success' && data.Hotels) {
            // console.log(data);
            data.Hotels.forEach((hotel) => {
              let distance = getDistanceFromLatLonInKm(
                urlParams.latitude,
                urlParams.longitude,
                hotel.Latitude,
                hotel.Longitude
              );
              hotel.Distance = Math.round(distance * 10) / 10;
            });

            //Order hotels by distance
            let newHotels = data.Hotels.sort((a, b) =>
              a.Distance > b.Distance ? 1 : -1
            );
            setHotels(newHotels);
            setIsLoading(false);
          } else if (
            urlParams.regNum === '000111' ||
            urlParams.regNum === '000222'
          ) {

            let res = fetchMockHotelsJSON(urlParams.radius).then((data) => {
              // console.log(data);
              data.Hotels.forEach((hotel) => {
                let distance = getDistanceFromLatLonInKm(
                  urlParams.latitude,
                  urlParams.longitude,
                  hotel.Latitude,
                  hotel.Longitude
                );
                hotel.Distance = Math.round(distance * 10) / 10;
              });

              //Order hotels by distance
              let newHotels = data.Hotels.sort((a, b) =>
                a.Distance > b.Distance ? 1 : -1
              );
              if (urlParams.regNum === '000222') {
                newHotels = [];
              }
              setHotels(newHotels);
              setIsLoading(false);
            });
          } else {
            console.log('No hotels found');
            console.log(data);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          console.log('Error!');
          console.log(err);
          setIsLoading(false);
        });
    }
  }, [urlParams.latitude, urlParams.latitude, urlParams.radius, urlParams.regNum]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.hash.split('?')[1]);
    const locationName = urlParams.get('search');
    if (locationName) {
      // Hide the google search;
      setIsLoading(true);
      // But give up if too slow
      setTimeout(() => setIsLoading(false), 1500);
    }
  }, []);

  //uses the Haversine formula to calculate distance between two points
  //https://stackoverflow.com/questions/27928/calculate-distance-between-two-latitude-longitude-points-haversine-formula
  function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    var R = 6371; // Radius of the earth in km
    var dLat = deg2rad(lat2 - lat1); // deg2rad below
    var dLon = deg2rad(lon2 - lon1);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);

    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = (R * c) / 1.609; // Distance in miles
    return d;
  }

  function deg2rad(deg) {
    return deg * (Math.PI / 180);
  }
  const handleHotelSelection = (hotel) => {
    setSelectedHotel(hotel);
  };

  const handleCloseOverlay = () => {
    setSelectedHotel(null);
  };

  return (
    <div className="results-wrap">
      <Loading isLoading={isLoading} />
      <div className="results-background">
        <img
          className="results-background-image"
          src={BackgroundImage}
          alt="hotel room key"
        />
      </div>
      <Navigation
        userLanguage={language}
        setUserLanguage={setLanguage}
        englishLabel={englishLabel}
        spanishLabel={spanishLabel}
      />
      <div className="results-list">
        <h1 className="hidden-heading">Results</h1>
        <div className="results-list-search-options">
          {/* 
          FILTERS COMING SOON
          <div className="results-list-search-options-left">
          </div>
          */}
          <div className="results-list-search-options-right">
            <a
              className="results-list-search-options-mobile-back"
              href="/app2023/#/find"
            >
              <div className="material-symbols-rounded">arrow_back</div>
            </a>
            <LocationSearchInput
              setLatitude={urlParams.setLatitude}
              setLongitude={urlParams.setLongitude}
            />
            <div className="results-list-search-options-search-radius">
              <select
                className="results-list-search-select"
                aria-label="Show Hotels Within"
                value={urlParams.radius}
                onChange={(e) => urlParams.setRadius(e.target.value)}
              >
                <option value="25">25 mi</option>
                <option value="50">50 mi</option>
                <option value="100">100 mi</option>
              </select>
              <div className="results-list-search-select-arrow material-symbols-rounded">
                expand_more
              </div>
            </div>
          </div>
        </div>
        <div className="results-list-overflow">
          <SimpleBar className="results-list-simplebar">
            {hotels && hotels.length > 0 && (
              <div className="results-list-overflow-inner">
                {hotels.map((hotel, index) => (
                  <ResultsListItem
                    key={index}
                    name={hotel.Name.toString()}
                    address={hotel?.Address_1?.toString()}
                    address2={hotel?.Address_2?.toString()}
                    city={hotel.City.toString()}
                    state={hotel.State.toString()}
                    zip={hotel.Zip.toString()}
                    phone={hotel.Phone.toString()}
                    ada={hotel.Amenities.ADA.toString()}
                    ada_comments={hotel.Amenities.ADA_Comments.toString()}
                    kitchen={hotel.Amenities.Kitchen.toString()}
                    kitchen_comments={hotel.Amenities.Kitchen_Comments.toString()}
                    pet={hotel.Amenities.Pets.toString()}
                    pets_comments={hotel.Amenities.Pets_Comments.toString()}
                    images={hotel.Images}
                    distance={hotel.Distance}
                    openOverlay={() => handleHotelSelection(hotel)}
                  />
                ))}
              </div>
            )}
            {hotels && hotels.length === 0 && (
              <div className="results-list-overflow-inner">
                <div className="result-error-message">{resultErrorMessage}</div>
              </div>
            )}
          </SimpleBar>
        </div>
      </div>
      <ResultsOverlay
        selectedHotel={selectedHotel}
        onClose={handleCloseOverlay}
      />
    </div>
  );
}

export default Results;
