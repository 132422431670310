import React from 'react';
import HotelsComponent from '../components/Hotel/HotelsComponent';
import { Link } from 'react-router-dom';
function HomeComponent() {
  return (
    <div>
      <HotelsComponent />
      <Link to="/#/results">Link to /results</Link>
    </div>
  );
}

export default HomeComponent;
