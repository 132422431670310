/**
 * @typedef {Object} Translations
 * @property {string} Code
 * @property {string} English
 * @property {string} Spanish
 */

/**create the translations object
 * @type {Translations}
 * */
const translations = [
    {Code: 'FemaTSA', en: 'FEMA TSA Lodging Providers', es: 'Proveedores de alojamiento FEMA TSA',},
    {Code: 'Eligible-1', 
    en: 'If you are eligible for Transitional Sheltering Assistance, FEMA will pay the cost to stay in certain lodging providers for a limited period. Costs covered are for the cost of the room and taxes, and any other expenses are not included. Search the list below of participating locations to see if they have availability in the area you have selected. Since room availability changes quickly, please call the lodging provider prior to travelling to be sure the lodging provider can accommodate your need.', 
    es: 'Si usted es elegible para la Asistencia de Refugio de Transición, FEMA pagará el costo de permanecer en ciertos proveedores de alojamiento por un período limitado. Los costos cubiertos son para el costo de la habitación y los impuestos, y cualquier otro gasto no está incluido. Busque en la siguiente lista de ubicaciones participantes para ver si tienen disponibilidad en el área que usted ha seleccionado. Debido a que la disponibilidad de habitaciones cambia rápidamente, por favor llame al proveedor de alojamiento antes de viajar para asegurarse de que el proveedor de alojamiento pueda satisfacer sus necesidades.'},
    {Code: 'Eligible-2', 
    en: 'Lodging through the Transitional Sheltering Assistance program may be limited to certain geographic areas. Use your FEMA registration number to access a list of participating lodging providers.', 
    es: 'El alojamiento a través del programa de Asistencia de Alojamiento de Transición puede estar limitado a ciertas áreas geográficas. Utilice su número de registro de FEMA para acceder a una lista de proveedores de alojamiento participantes.'},
    {Code: 'Enter-RegNumber', en: 'Enter Your Registration Number', es: 'Introduzca su número de registro'},
    {Code: 'Submit', en: 'Submit', es: 'Enviar'},
    {Code: 'Place-To-Stay', en: 'Find pre-approved places to stay', es: 'Encuentre alojamientos preaprobados'},
    {Code: 'Search-Results-bar', en: 'Search City or County or Zip', es: 'Buscar ciudad, condado o código postal'},
    {Code: 'Hotels-Within', en: 'Show Hotels Within', es: 'Mostrar hoteles'},
    {Code: 'Show-Results', en: 'Show Results', es: 'Resultados'},
    {Code: 'Filters', en: 'Filters', es: 'Filtros'},
    {Code: 'Show-Number-Results', en: 'Showing {resultsCount} Results', es: 'Mostrando {resultsCount} resultados'},
    {Code: 'Pets-Allowed', en: 'Pets Allowed', es: 'Se admiten mascotas'},
    {Code: 'Pets-Not-Allowed', en: 'Pets Not Allowed', es: 'No se admiten animales'},
    {Code: 'ADA-Accommodations', en: 'ADA Accommodations', es: 'Adaptaciones ADA'},
    {Code: 'No-ADA-Accommodations', en: 'No ADA Accommodations', es: 'Sin adaptaciones ADA'},
    {Code: 'No-Meals-Provided', en: 'No Kitchenettes', es: 'Sin cocina'},
    {Code: 'Meals-Provided', en: 'Hotel Has Kitchenettes', es: 'El hotel tiene cocina'},
    {Code: 'Phone', en: 'Phone:', es: 'Teléfono:'},
    {Code: 'Show-Hotels-Within', en: 'Show Hotels Within (miles)', es: 'Mostrar hoteles en (millas)'},
    {Code: 'Hotel-Chain-(name)', en: 'Hotel Chain (name)', es: 'Cadena hotelera (nombre)'},
    {Code: 'Zipcode-Within-Radius', en: 'Zipcode (within {searchRadius} mi radius)', es: 'Código postal (en un radio de {searchRadius} millas)'},
    {Code: 'City-Within-Radius', en: 'City (within {searchRadius} mi radius)', es: 'Ciudad (en un radio de {searchRadius} millas)'},
    {Code: 'County-Within-Radius', en: 'County (within {searchRadius} mi radius)', es: 'Condado (en un radio de {searchRadius} millas)'},
    {Code: 'Reset-All', en: 'Reset All', es: 'Restablecer todo'},
    {Code: 'To-Book-Please-Call', en: 'Call Hotel Now', es: 'Llame ahora al hotel'},
    {Code: 'No-Hotel-In-Reg-Num', en: 'There is not a hotel for your registration code in this area. Try searching in one of these states:', es: 'No hay ningún hotel para su código de registro en esta zona. Intenta buscar en uno de estos estados:'},
    {Code: 'Select State', en: 'Select State', es: 'Seleccionar Estado'},
    {Code: 'Map-View', en: 'Map View', es: 'Ver mapa'},
    {Code: 'List-View', en: 'List View', es: 'Ver lista'},
    {Code: 'Yes', en: 'Yes', es: 'Sí'},
    {Code: 'No', en: 'No', es: 'No'},
    {Code: 'Request-Timed-Out', en: 'The request has timed out.  Please try again.', es: 'La solicitud ha expirado.  Por favor, inténtelo de nuevo.'},
    {Code: 'No-Hotels-Found', en: 'No hotels found.', es: 'No se han encontrado hoteles.'},
    {Code: 'RegNumber-Ineligible-1', en: 'Your registration number is ineligible for the FEMA TSA Lodging program.', es: 'Su número de registro no es elegible para el programa FEMA TSA Lodging.'},
    {Code: 'RegNumber-Ineligible-2', en: 'Please double-check your registration number and try again.', es: 'Compruebe su número de registro e inténtelo de nuevo.'},
    {Code: 'More-Details', en: 'More details about {0}', es: 'Más información sobre {0}'},
    {Code: 'Kitchen-In-Room', en: 'Kitchen in Room', es: 'Cocina en habitación'},
    {Code: 'Num-of-Rooms-With-ADA', en: '# of Rooms with ADA Accomodations', es: '# Número de habitaciones adaptadas a la ADA'},
    {Code: 'Unknown', en: 'Unknown', es: 'Desconocido'},
    {Code: 'Back', en: 'Back', es: 'Volver'},
    {Code: 'Results', en: 'Results', es: 'Resultados'},
    {Code: 'Forgot-RegNumber', en: 'Forgot your registration number?', es: '¿Ha olvidado su número de registro?'}, 
    {Code: 'Forgot-RegNumber-Detail-1', en: 'To find your registration number you can check online at DisasterAssistance.gov, call the FEMA Helpline at 800-621-3362, through the FEMA mobile app, or visit a Disaster Recovery Center (DRC). To find a DRC go to <a href="https://egateway.fema.gov/ESF6/DRCLocator" target="_blank">https://egateway.fema.gov/ESF6/DRCLocator</a>', es: 'Para encontrar su número de registro puede comprobarlo en línea en DisasterAssistance.gov, llamar a la línea de ayuda de FEMA al 800-621-3362, a través de la aplicación móvil de FEMA, o visitar un Centro de Recuperación de Desastres (DRC). Para encontrar un DRC vaya a <a href="https://egateway.fema.gov/ESF6/DRCLocator" target="_blank">https://egateway.fema.gov/ESF6/DRCLocator</a>'},
    {Code: 'Forgot-RegNumber-Detail-2', en: 'If you use a video relay service (VRS), captioned telephone service, or other communication services, give FEMA your number for that service', es: 'Si utiliza un servicio de retransmisión de vídeo (VRS), un servicio telefónico con subtítulos u otros servicios de comunicación, facilite a FEMA el número de dicho servicio'},
    {Code: 'Field-Not-Empty', en: 'This field cannot be empty', es: 'Este campo no puede estar vacío'},
];

export function FetchTranslationByCodeAndLanguage(code, language) {
  // console.log('FetchTranslation -', code, "for", language);
    const translation = translations.find(t => t.Code === code);
    return translation ? translation[language] : '';
}

export function FetchTranslationsByLanguage(language) {
 let tempDictionary = [];
 switch (language) {
    case 'en':
      translations.forEach(t => {
        tempDictionary.push({code: t.Code, text: t.en});
      });
      break;
    case 'es':
      translations.forEach(t => {
        tempDictionary.push({code: t.Code, text: t.es});
      });
      break;
    default:
      translations.forEach(t => {
        tempDictionary.push({code: t.Code, text: t.en});
      });
      break;
  }

// console.log('tempdictionart: ',  tempDictionary)
  return tempDictionary;
}
