/**
 * @typedef {Object} Amenities
 * @property {string} Pets
 * @property {string} Kitchen
 * @property {string} ADA
 */

/**
 * @typedef {Object} Image
 * @property {string} ImageURL
 */

/**
 * @typedef {Object} Hotel
 * @property {string} Name
 * @property {string} City
 * @property {string} State
 * @property {string} Zip
 * @property {string} Phone
 * @property {number} Latitude
 * @property {number} Longitude
 * @property {Amenities[]} Amenities
 * @property {Image[]} Images
 */

/**
 * @typedef {Object} HotelSuccessRes
 * @property {string} Status
 * @property {string} Error Message
 * @property {Hotel[]} Hotel
 */

/** @type {HotelSuccessRes} */
const hotelSuccessRes = {
  Status: 'success',
  'Error Message': '',
  Hotel: [
    {
      Name: 'DAYS INN LOS ANGELES (10023)',
      City: 'Los Angeles',
      State: 'LA',
      Zip: '77036-2441',
      Phone: '555-555-5555',
      Latitude: 29.712362,
      Longitude: -95.547189,
      Amenities: [
        {
          Pets: 'N',
          Kitchen: 'Y',
          ADA: 'Y',
        },
      ],
      Images: [
        {
          ImageURL: 'https://account.clclodging.com/images/example2.jpg',
        },
      ],
    },
    {
      Name: 'RED ROOF INN (50033)',
      City: 'HOUSTON',
      State: 'TX',
      Zip: '77039-2441',
      Phone: '555-555-5555',
      Latitude: 29.712433,
      Longitude: -95.547233,
      Amenities: [
        {
          Pets: 'Y',
          Kitchen: 'Y',
          ADA: 'Y',
        },
      ],
      Images: [
        {
          ImageURL: 'https://account.clclodging.com/images/example2.jpg',
        },
      ],
    },
  ],
};

const MockHotelJSON = {Status:"SUCCESS",['Error Message']:"",Hotels:[
{Name:"EXTENDED STAY AMERICA (13798)",VendorID:"80058",Address_1:"2100 CINEMA DR",Address_2:"",City:"AMARILLO",State:"TX",Zip:"79124-2113",Phone:"(806) 351-0117",Latitude:"35.191367",Longitude:"-101.933537",Amenities:{Pets:"Y",Pets_Comments:"2 Pets under 40lbs - $25\/night\/pet for first six nights of every month stayed",Kitchen:"Y",Kitchen_Comments:"Professional Chefs on staff",ADA:"N",ADA_Comments:""},Images:{ImageURL:["https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38636_M0_External.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38636_I10_HotelGeneral.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38636_I1_External.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38636_I2_LobbyInterior.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38636_I3_Room.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38636_I4_Room.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38636_I5_Room.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38636_I6_HotelGeneral.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38636_I7_HotelGeneral.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38636_I8_HotelGeneral.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38636_I9_HotelGeneral.jpg"]}},
{Name:"VALUE PLACE AMARILLO (71277)",VendorID:"278135",Address_1:"4601 I-40 EAST",Address_2:"SECOND ADDRESS TEST",City:"AMARILLO",State:"TX",Zip:"79104-4262",Phone:"(806) 342-0222",Latitude:"35.192915",Longitude:"-101.779861",Amenities:{Pets:"N",Pets_Comments:"",Kitchen:"Y",Kitchen_Comments:"",ADA:"N",ADA_Comments:""},Images:{ImageURL:["https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38597_M0_LobbyInterior.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38597_I10_HotelGeneral.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38597_I1_LobbyInterior.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38597_I2_LobbyInterior.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38597_I3_Room.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38597_I4_HotelGeneral.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38597_I5_HotelGeneral.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38597_I6_HotelGeneral.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38597_I7_HotelGeneral.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38597_I8_HotelGeneral.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38597_I9_HotelGeneral.jpg"]}},
{Name:"DAYS INN (43518)",VendorID:"24793",Address_1:"1701 E INTERSTATE 40",Address_2:"",City:"AMARILLO",State:"TX",Zip:"79102-4325",Phone:"(806)379-6255",Latitude:"35.192852",Longitude:"-101.821184",Amenities:{Pets:"Y",Pets_Comments:"$10.00 additional fee",Kitchen:"N",Kitchen_Comments:"",ADA:"N",ADA_Comments:""},Images:{ImageURL:["https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38579_M0_External.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38579_I10_HotelGeneral.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38579_I1_External.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38579_I2_LobbyInterior.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38579_I3_Room.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38579_I4_Room.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38579_I5_Room.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38579_I6_BusinessCenter.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38579_I7_HotelGeneral.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38579_I8_HotelGeneral.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38579_I9_HotelGeneral.jpg"]}},
{Name:"LA QUINTA INN #454 (43758)",VendorID:"27161",Address_1:"1708 E INTERSTATE 40",Address_2:"",City:"AMARILLO",State:"TX",Zip:"79103-2114",Phone:"(806)3737486",Latitude:"35.192028",Longitude:"-101.819566",Amenities:{Pets:"Y",Pets_Comments:"Two pets allowed per room",Kitchen:"N",Kitchen_Comments:"",ADA:"N",ADA_Comments:""},Images:{ImageURL:["https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38583_M0_External.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38583_I10_HotelGeneral.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38583_I1_External.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38583_I2_LobbyInterior.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38583_I3_Room.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38583_I4_Room.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38583_I5_Room.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38583_I6_HotelGeneral.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38583_I7_HotelGeneral.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38583_I8_HotelGeneral.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38583_I9_HotelGeneral.jpg"]}},
{Name:"BAYMONT INN AND SUITES (73943)",VendorID:"162024",Address_1:"1700 E INTERSTATE 40",Address_2:"",City:"AMARILLO",State:"TX",Zip:"79103-2114",Phone:"(806)3721425",Latitude:"35.192025",Longitude:"-101.819657",Amenities:{Pets:"N",Pets_Comments:"",Kitchen:"N",Kitchen_Comments:"",ADA:"N",ADA_Comments:""},Images:{ImageURL:["https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38584_M0_External.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38584_I10_HotelGeneral.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38584_I1_External.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38584_I2_LobbyInterior.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38584_I3_Room.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38584_I4_Room.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38584_I5_Room.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38584_I6_BusinessCenter.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38584_I7_PoolGym.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38584_I8_HotelGeneral.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38584_I9_HotelGeneral.jpg"]}},
{Name:"BEST WESTERN (29874)",VendorID:"150692",Address_1:"2801 4TH AVE",Address_2:"",City:"CANYON",State:"TX",Zip:"79015-4227",Phone:"(806)6551818",Latitude:"34.979783",Longitude:"-101.910523",Amenities:{Pets:"Y",Pets_Comments:"20$ charge per night",Kitchen:"N",Kitchen_Comments:"",ADA:"N",ADA_Comments:""},Images:{ImageURL:["https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38505_M0_External.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38505_I10_HotelGeneral.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38505_I1_External.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38505_I2_LobbyInterior.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38505_I3_Room.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38505_I4_Room.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38505_I5_Room.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38505_I6_BusinessCenter.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38505_I7_PoolGym.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38505_I8_HotelGeneral.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38505_I9_HotelGeneral.jpg"]}},
{Name:"BAYMONT INN & SUITES (43814)",VendorID:"27771",Address_1:"3411 W INTERSTATE 40",Address_2:"",City:"AMARILLO",State:"TX",Zip:"79109-1502",Phone:"(806)356-6800",Latitude:"35.190456",Longitude:"-101.876924",Amenities:{Pets:"Y",Pets_Comments:"15.00 per pet per night",Kitchen:"N",Kitchen_Comments:"",ADA:"N",ADA_Comments:""},Images:{ImageURL:["https:\/\/account.clclodging.com\/hotel_images\/no_hotel_results.jpg"]}},
{Name:"RED ROOF INN F (53176)",VendorID:"33194",Address_1:"1620 E INTERSTATE 40",Address_2:"",City:"AMARILLO",State:"TX",Zip:"79103-2117",Phone:"(806)3742020",Latitude:"35.192011",Longitude:"-101.820595",Amenities:{Pets:"Y",Pets_Comments:"First pet free",Kitchen:"N",Kitchen_Comments:"",ADA:"Y",ADA_Comments:"ADA COMMENTS HERE"},Images:{ImageURL:["https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38585_M0_External.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38585_I10_HotelGeneral.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38585_I1_External.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38585_I2_LobbyInterior.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38585_I3_Room.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38585_I4_Room.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38585_I5_Room.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38585_I6_HotelGeneral.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38585_I7_HotelGeneral.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38585_I8_HotelGeneral.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_38585_I9_HotelGeneral.jpg"]}},
{Name:"TRAVELODGE (43648)",VendorID:"26045",Address_1:"2035 PARAMOUNT BLVD",Address_2:"",City:"AMARILLO",State:"TX",Zip:"79109-1611",Phone:"(806)3533541",Latitude:"35.191607",Longitude:"-101.873365",Amenities:{Pets:"N",Pets_Comments:"",Kitchen:"N",Kitchen_Comments:"",ADA:"N",ADA_Comments:""},Images:{ImageURL:["https:\/\/account.clclodging.com\/hotel_images\/no_hotel_results.jpg"]}},
{Name:"BEST WESTERN PLUS MEDICAL CENTER HOTEL (86318)",VendorID:"396954",Address_1:"7410 W. AMARILLO BLVD",Address_2:"",City:"AMARILLO",State:"TX",Zip:"79106",Phone:"(806) 803-5000",Latitude:"35.1927378",Longitude:"-101.9226387",Amenities:{Pets:"N",Pets_Comments:"",Kitchen:"N",Kitchen_Comments:"",ADA:"N",ADA_Comments:""},Images:{ImageURL:["https:\/\/account.clclodging.com\/hotel_imaes\/HCIMG_146614_M0_External.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_146614_I10_HotelGeneral.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_146614_I1_External.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_146614_I2_LobbyInterior.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_146614_I3_Room.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_146614_I4_Room.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_146614_I5_Room.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_146614_I6_BusinessCenter.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_146614_I7_HotelGeneral.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_146614_I8_HotelGeneral.jpg","https:\/\/account.clclodging.com\/hotel_images\/HCIMG_146614_I9_HotelGeneral.jpg"]}}]};

export function fetchMockHotelsJSON(radius) {
  return new Promise((resolve) => {

    const hotels = MockHotelJSON.Hotels;
    let count = hotels.length;
    count = hotels.length * (radius / 100);
    count = Math.ceil(count);
    const newHotelList = hotels.slice(0, count);
    resolve({...MockHotelJSON, Hotels: newHotelList});
  });
}

export function fetchHotelData() {
  return new Promise((resolve) => {
    resolve(hotelSuccessRes);
  });
}

export function fetchHotelsByCity(city) {
  return new Promise((resolve) => {
    const hotels = hotelSuccessRes.Hotel.filter(
      (hotel) => hotel.City.toLowerCase().includes(city.toLowerCase()) // Case insensitive and partial match - MG
    );
    resolve({ ...hotelSuccessRes, Hotel: hotels });
  });
}

export function fetchHotelsByState(state) {
  return new Promise((resolve) => {
    const hotels = hotelSuccessRes.Hotel.filter(
      (hotel) => hotel.State === state
    );
    resolve({ ...hotelSuccessRes, Hotel: hotels });
  });
}

export function fetchHotelsByZip(zip) {
  return new Promise((resolve) => {
    const hotels = hotelSuccessRes.Hotel.filter(
      (hotel) => hotel.Zip.split('-')[0] === zip
    );
    resolve({ ...hotelSuccessRes, Hotel: hotels });
  });
}

//Current Fetch loses city search on 2nd input due to the state search taking over. - MG
export function fetchHotelsByInput(input) {
  // Regular expressions to check input type
  const zipReg = /^\d{5}$/;
  const stateReg = /^[A-Za-z]{2}$/;

  if (zipReg.test(input)) {
    // Input is a zip code
    return fetchHotelsByZip(input);
  } else if (stateReg.test(input)) {
    // Input is a state
    return fetchHotelsByState(input);
  } else {
    // Input is a city
    return fetchHotelsByCity(input);
  }
}
