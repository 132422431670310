import React from 'react';
import './ResultsOverlay.css';
import GallerySlider from '../GallerySlider/GallerySlider';
import DetailsInfo from './DetailsInfo';
import { translationContext } from '../../App';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
function handleExpand(e) {
  e.preventDefault();
  e.target.parentNode.classList.toggle('expanded');
}

function ResultsOverlay({ selectedHotel, onClose }) {
  const translations = React.useContext(translationContext);
  const dictionary = translations.translations;
  const KitchenInRoom = dictionary.find(
    (translation) => translation.code === 'Kitchen-In-Room'
  ).text;
  const NumRoomsWithADA = dictionary.find(
    (translation) => translation.code === 'Num-of-Rooms-With-ADA'
  ).text;
  const Unknown = dictionary.find(
    (translation) => translation.code === 'Unknown'
  ).text;
  const ToBookPleaseCall = dictionary.find(
    (translation) => translation.code === 'To-Book-Please-Call'
  ).text;
  if (!selectedHotel) {
    return null;
  }
  const handleClose = () => {
    onClose();
  };

  return (
    <div className="results-overlay-wrap">
      <div className="results-overlay">
        <a className="results-overlay-close" onClick={handleClose}>
          <span className="material-symbols-rounded">close</span>
        </a>
        <div className="results-overlay-flex">
          <SimpleBar style={{ maxHeight: '100%' }} autoHide={false}>
            <div>
              <div className="results-overlay-slide-wrap">
                <GallerySlider
                  hotelName={selectedHotel.Name}
                  images={selectedHotel.Images}
                />
                {/* TODO: Wire up if location has been viewed and called. Only show 1 flag max. */}
                <div className="results-overlay-slide-flag">viewed</div>
                {/* <div className="results-overlay-slide-flag loud">called</div> */}
              </div>
              <div className="results-overlay-content">
                <p className="results-overlay-title">{selectedHotel.Name}</p>
                <p className={'results-overlay-address no-margin-bottom'}>
                  {selectedHotel.Address_1}
                </p>

                {selectedHotel.Address_2 !== '' && (
                  <p className="results-overlay-address no-margin-bottom ">
                    {selectedHotel.Address_2}
                  </p>
                )}
                <p className="results-overlay-address">
                  {`${selectedHotel.City}, ${selectedHotel.State} ${selectedHotel.Zip}`}
                </p>

                {/* This is for phase2 */}
                <p
                  className="results-overlay-summary"
                  style={{ display: 'none' }}
                >
                  {KitchenInRoom}: <b>{Unknown}</b> <br />
                  {NumRoomsWithADA}: <b>{Unknown}</b>
                </p>
                <div className="results-overlay-feature-list">
                  <DetailsInfo
                    pets={selectedHotel.Amenities.Pets}
                    pets_comments={selectedHotel.Amenities.Pets_Comments}
                    ada={selectedHotel.Amenities.ADA}
                    ada_comments={selectedHotel.Amenities.ADA_Comments}
                    kitchen={selectedHotel.Amenities.Kitchen}
                    kitchen_comments={selectedHotel.Amenities.Kitchen_Comments}
                  />
                </div>

                <div className="results-overlay-step-wrap">
                  <div className="results-overlay-step">
                    <span className="results-overlay-step-number">1</span>
                    <span className="results-overlay-step-title">
                      Search for a hotel
                    </span>
                    <span className="results-overlay-step-text">
                      Use the form to find a hotel that meets your needs.
                      You&apos;ll need the registration ID provided you applied
                      for assistance.
                    </span>
                  </div>
                  <div className="results-overlay-step">
                    <span className="results-overlay-step-number">2</span>
                    <span className="results-overlay-step-title">
                      Call the hotel
                    </span>
                    <span className="results-overlay-step-text">
                      Tell them you were referred by FEMA. Provide the hotel
                      with X, Y, and Z.
                    </span>
                  </div>
                  <div className="results-overlay-step">
                    <span className="results-overlay-step-number">3</span>
                    <span className="results-overlay-step-title">
                      FEMA will cover your costs
                    </span>
                    <span className="results-overlay-step-text">
                      FEMA will cover your hotel costs for as long as you are
                      unable to live in your home.
                    </span>
                  </div>
                </div>
                <div className="results-overlay-faq-wrap">
                  <p className="results-overlay-faq-heading">Need Help?</p>
                  <div className="results-overlay-faq-item">
                    <button
                      className="results-overlay-faq-question"
                      onClick={handleExpand}
                    >
                      Here&apos;s an FAQ question about hotels with some
                      wrapping lines?
                    </button>
                    <span className="results-overlay-faq-answer">
                      Here&apos;s an answer for FAQs with some wrapping lines
                    </span>
                  </div>
                  <div className="results-overlay-faq-item">
                    <button
                      className="results-overlay-faq-question"
                      onClick={handleExpand}
                    >
                      Here&apos;s an FAQ question about hotels with some
                      wrapping lines?
                    </button>
                    <span className="results-overlay-faq-answer">
                      Here&apos;s an answer for FAQs with some wrapping lines
                    </span>
                  </div>
                  <div className="results-overlay-faq-item">
                    <button
                      className="results-overlay-faq-question"
                      onClick={handleExpand}
                    >
                      Here&apos;s an FAQ question about hotels with some
                      wrapping lines?
                    </button>
                    <span className="results-overlay-faq-answer">
                      Here&apos;s an answer for FAQs with some wrapping lines
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </SimpleBar>
        </div>
        <a
          className="results-overlay-booking-snackbar"
          href={'tel:+1-' + selectedHotel.Phone}
        >
          <span className="results-overlay-booking-instructions">
            {ToBookPleaseCall}
          </span>
          <span className="results-overlay-booking-phone">
            {selectedHotel.Phone}
          </span>
        </a>
      </div>
    </div>
  );
}

export default ResultsOverlay;
